import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
//import { Document, Page } from "@react-pdf/renderer";
import Pagination from "../Pagination/Pagination";
import axios from 'axios';

import classes from "./PdfViewer.module.scss";
/**
 * Fixes error https://github.com/wojtekmaj/react-pdf/issues/321
 * Maybe not the best aproach
 */
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
/*
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();
*/
interface IProps {
  url: string;
  data?:any;
  width?: number;
  height?: number;

  firstPage?: number;
  lastPage?: number;
  viewType?: string;
}

const defaultWidth = 500;
const defaultHeight = 315;

const PdfViewer: React.FC<IProps> = ({
 url,
 data,
 width = defaultWidth,
 height = defaultHeight,
 firstPage = null,
 lastPage = null,
 viewType = "",
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [firstNumber, setFirst] = useState<number>(0);
  const [lastNumber, setLast] = useState<number>(0);

  //const [pdfFile, setPdfFile] = useState<any>(null);


  const getLastPage = (no: number) => {
    return (lastPage?Math.min(lastPage,no):no)-1;
  }

  const getFirstPage = (last: number) => {
    return firstPage?Math.min(firstPage-1,last):0;
  }

  const getNumPages = () => {
    return lastNumber - firstNumber + 1;
  }

  const updatePages = (num: number) => {
    setNumPages(num);
    const _last = getLastPage(num);
    setLast(_last);
    const _first = getFirstPage(_last);
    setFirst(_first);
  }

  useEffect(()=>{
    updatePages(numPages);
  },[firstPage, lastPage])
/*
  useEffect(()=>{
    //if(data && pdfFile === null) {

          (async () => {
            try {
              const response = await axios.post(url, data, {"responseType":'blob'});
              const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
              const pdfUrl = URL.createObjectURL(pdfBlob);
              setPdfFile(pdfUrl);
            } catch(err) {
              console.log(err);
            }
          })();


    //}
  },[]);
*/
  const pdfFile = data;
  
  return (
    <div className={classes.Container}>
    {viewType !== "on"
      ?<>
      <Pagination
        numOfPages={numPages}
        page={pageNumber}
        setPage={setPageNumber}
      />
      <Document
        file={pdfFile?pdfFile:url}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        onLoadError={console.error}
        
      >
        <Page
          className={classes.Page}
          pageNumber={pageNumber}
          // width={width}
          // height={height}
          
        />
      </Document>
      </>:<>
      <Document
        file={pdfFile?pdfFile:url}
        onLoadSuccess={({ numPages }) => updatePages(numPages)}
        onLoadError={console.error}
        renderMode="canvas"
      >
        {[...Array(getNumPages())].map((e,i) => (<Page
          key={i}
          className={classes.MultiPage}
          pageNumber={i+1+firstNumber}
          width={2000}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          //height={600*0.515}
        />))}
      </Document>
      </>}
      

    </div>
  );
};

export default React.memo(PdfViewer);
