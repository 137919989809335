import React, { useEffect, useState } from "react";
import EmbeddedVimeo from "../../../../../../../components/UI/Embedded/EmbedVimeo/EmbedVimeo";
import EmbedYoutube from "../../../../../../../components/UI/Embedded/EmbedYoutube/EmbedYoutube";
import { EVideoSource, EVisualType } from "../../../../../../../enums";
import {
  IBlockContent,
  IBlockContentVisual,
  IOffer,
} from "../../../../../../../interfaces";
import BlockContentImageShow from "../BlockContentImage/BlockContentImageShow";
import PdfViewer from "../../../../../../../components/UI/PdfViewer/PdfViewer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { config } from '../../../../../../../constants';

import axios from 'axios';

import classes from "./BlockContentVisualShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent: boolean;
  offer?:IOffer;
}


const BlockContentVisualShow: React.FC<IProps> = props => {
  const blockContent = props.blockContent as IBlockContentVisual;
  const showDummyContent = props.showDummyContent;

  const [pdfFile, setPdfFile] = useState<any>(null);
  const [oldOffer, setOldOffer] = useState<string>(null);


  if(showDummyContent && !blockContent.visualType) {
    blockContent.visualType = EVisualType.IMAGE;
  }

  const renderVideo = (blockContent: IBlockContentVisual) => {
		let content = null;

		if(!blockContent.videoEmbed) {
			return (
				<div className="text-center"><FontAwesomeIcon icon={faVideo} size="2x" /><br/>Missing VIDEO</div>
			)
		}

    switch (blockContent.videoSource) {
			case EVideoSource.YOUTUBE:
        content = (
          <EmbedYoutube
            id={blockContent.videoEmbed}
            width={blockContent.width}
            height={blockContent.height}
          />
        );
        break;
			case EVideoSource.VIMEO:
        content = (
          <EmbeddedVimeo
            id={blockContent.videoEmbed}
            width={blockContent.width}
            height={blockContent.height}
          />
        );
        break;
      default:
        return null;
    }
    return <div className={classes.Container}>{content}</div>;
  };

  const renderPdf = (blockContent: IBlockContentVisual) => {
		if (!blockContent.pdfs || blockContent.pdfs.length === 0) {
			return (
				<div className="text-center"><FontAwesomeIcon icon={faFilePdf} size="2x" /><br/>Missing PDF</div>
			);
		}
    
    return (
      <div className={classes.Container}>
        <PdfViewer
          url={blockContent.pdfs[0].link}
          width={+blockContent.width}
          height={+blockContent.height}

          firstPage={blockContent.firstPage || null}
          lastPage={blockContent.lastPage || null}
          viewType={blockContent.viewType || "off"}
        />
      </div>
    );
  };

  useEffect(() => {
    const p = JSON.stringify(props.offer);
    if(oldOffer!==p) {
      setPdfFile(null);
      setOldOffer(p);
    }
  },[props]);

  const renderDynamicPdf = (blockContent: IBlockContentVisual) => {
    let url=config.CUSTOMIZED_URI+"/001/pdf/";
    if(pdfFile === null) {
          (async () => {
            try {
              const response = await axios.post(url, props.offer, {"responseType":'blob', "withCredentials": false,});
              const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
              const pdfUrl = URL.createObjectURL(pdfBlob);
              setPdfFile(pdfUrl);
            } catch(err) {
              console.log(err);
            }
          })();
    }
    if(pdfFile === null) return null;

    return (

      <div className={classes.Container}>
        <PdfViewer
          url={url}
          data={pdfFile}
          width={+blockContent.width}
          height={+blockContent.height}

          firstPage={null}
          lastPage={null}
          viewType={"on"}
        />
      </div>
    );
  };

  return (() => {
    switch (blockContent.visualType) {
      case EVisualType.IMAGE:
        return <BlockContentImageShow blockContent={blockContent} showDummyContent={showDummyContent} />;
      case EVisualType.PDF:
        return renderPdf(blockContent);
      case EVisualType.DYNAMIC_PDF:
        return renderDynamicPdf(blockContent);
      case EVisualType.VIDEO:
        return renderVideo(blockContent);
      default:
        return null;
    }
  })();
};

export default BlockContentVisualShow;
