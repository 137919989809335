import { addDays } from "date-fns";
import update from "immutability-helper";
import _ from "lodash";
import * as qs from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps, withRouter } from '../../../../withRouter';

import { useParams } from "react-router-dom";

import { Alert, Spinner, Button } from "reactstrap";
import uuid from "uuid";
import CustomDialog from "../../../../components/UI/CustomDialog/CustomDialog";
import { EOfferStatus, EPageView, EUserTemplateBlockType } from "../../../../enums";
import EUserRole from "../../../../enums/user-roles";
import { IAppState, IBlock, IBlockContent, IBlockItem, ICustomer, IOffer, IOrganization, IUser, IUserTemplate, IUserTemplateAreaBlock, IProduct, IOfferHistory } from "../../../../interfaces";
import { defaultOffer, isOfferDone } from "../../../../shared/offer-utils";
import { dateToString, isValidEmail, stringToDate } from "../../../../shared/utility";
import * as actions from "../../../../store/actions";
import OfferBlockItemModal, { defaultBlockItemModal } from "../../components/Offers/Offer/OfferBlockItemModal/OfferBlockItemModal";
import OfferEdit from "../../components/Offers/Offer/OfferEdit/OfferEdit";

import OfferFooter from "./EmbeddedOfferFooter";

import OfferMessagingSidebarSimple from "../../components/Offers/Offer/OfferMessagingSidebar/OfferMessagingSidebarSimple";
import OfferPreview from "../../components/Offers/Offer/OfferPreview/OfferPreview";
import OfferSendDialog from "./EmbeddedSendDialog";
import { EBlockEditingStyle } from "../Blocks/Block/Block";
import OfferContext from "../Offers/Offer/OfferContext";



import { IOfferComment } from "../../../../interfaces";

import TextareaAutosize from "react-textarea-autosize";
import { Form, FormGroup, Input } from "reactstrap";


import { faEnvelope, faHistory, faComments, faFileContract } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import { useSessionStorage} from '../../../../shared/useSessionStorage';

import classes from "./Embedded.module.scss";

import inputClasses from "../../../../components/UI/Input/Input.module.scss";


interface IStateProps {
  customers: Array<ICustomer>;
  customersLoading: boolean;
  customer: ICustomer;
  userTemplates: Array<IUserTemplate>;
  userTemplatesLoading: boolean;
  offersLoading: boolean;
  offersError: string;
  organization: IOrganization;
  commentLoading: boolean;
  commentError: string;
  emailLoading: boolean;
}

interface IDispatchProps {
  onFindUserTemplates: () => Promise<Array<IUserTemplate>>;
  onFindBlockItems: (id: string) => Promise<Array<IBlockItem>>;
  onGetBlockItem: (id: string) => Promise<IBlockItem>;
  onSaveOffer: (offer: IOffer) => Promise<IOffer>; //<boolean>;
  onUpdateOffer: (id: string, offer: IOffer, params: string) => Promise<IOffer>; //<boolean>;
  onGetOffer: (id: string) => Promise<IOffer>;
  onSaveBlockItem: (blockId: string, blockITem: IBlockItem) => Promise<IBlockItem>;
  onGetBlock: (id: string) => Promise<IBlock>;
  onGetUserOrganization: () => Promise<IOrganization>;

  onGetEmbedded: (id:string, func:string, params?:string)=>Promise<any>;
  onPostEmbedded: (id:string, func:string, data:any, application:string)=>Promise<any>;

  onSearchCustomers: (queryString: string) => void;
  onGetCustomer: (id: string) => Promise<ICustomer>;

  //onCommentOffer: (data: Object) => Promise<IOffer>;
}

interface IParams {
  id: string;
  type: EPageView;
  dealid: string;
  userEmail: string;
}

interface IProps
  extends RouteComponentProps, //<IParams>,
    IStateProps,
    IDispatchProps {}

const defaultConfirmDialog = {
  isOpen: false,
  onOk: () => {},
  title: "",
  content: ""
};

interface IOfferBlockItems {
  [propertyName: string]: IBlockItem;
}

/**
 * Tää on aika kauhee sotku, pitää miettiä miten sais tehtyä paremmin.
 *
 */
const EmbeddedOffer: React.FC<IProps> = ({
  organization,
  customers,
  customersLoading,
  userTemplatesLoading,
  offersLoading,
  commentError,
  commentLoading,
  onFindUserTemplates,
  onFindBlockItems,
  onGetBlockItem,
  onGetOffer,
  onGetBlock,
  onSaveOffer,
  onUpdateOffer,
  onGetUserOrganization,
  match,
  history,
  offersError,
  emailLoading,

  location,
  onSaveBlockItem,

  onGetEmbedded,
  onPostEmbedded,

  onSearchCustomers,
  onGetCustomer,

  //onCommentOffer,
}) => {

  const params=useParams();
  const id:string = params.id;
  const type:EPageView = params.type as EPageView;
  const dealid:string = params.dealid;
  const userEmail:string = params.userEmail;

//  const { id, type, dealid, userEmail } = match.params;


  const [offerList, setOfferList] = useState<Array<string>>([]);

  const [embedded, setEmbedded] = useState<any>(null);
  const [userTemplates, setUserTemplates] = useState<Array<IUserTemplate>>([]);

  const attributes:any = qs.parse(location.search);

  let application:string = "hubspot";
  let sessionId:string = null;

  window.embeddedAuth=userEmail;

  if(attributes.integration == "salesforce") {
    window.embeddedAuth=btoa(JSON.stringify({a:"salesforce",s:userEmail,u:attributes.userId,c:attributes.organizationId,o:attributes.opportunityId,email:attributes.email, instance_url: attributes.instance_url}));
    application="salesforce";
    sessionId = attributes.opportunityId as string;
  }
  else if(attributes.integration == "dynamics") {
    const orgid = userEmail; //"ag5sZWdhbHJpLTE2NDQxMHIZCxIMT3JnYW5pemF0aW9uGICAgICAgIAKDA"; // attributes.OrgLCID
    const email = "seppo@click2contract.com";
    
    window.embeddedAuth=btoa(JSON.stringify({a:"dynamics",user:attributes.user, s:userEmail,u:attributes.UserLCID,c:orgid,o:attributes.id, email:email, referrer: document.referrer}));
    application="dynamics";
    sessionId = attributes.opportunityId as string;
  }

  //console.log(application,attributes);

  useEffect(() => {
    (async () => {

      let _embedded:any = JSON.parse(sessionStorage.getItem("embedded")) || null;

      const uid = attributes.useid || sessionStorage.getItem("useId") || "";

      const useId = uid?"?useid="+uid:"";

      const [data] = await Promise.all([
          onGetEmbedded(application,"proposal", useId)
        ]);

      if(data) {
        setEmbedded({...data,embedded:true, application:application});

        setOfferList(data.offerlist);
        if(data.offer) {
          if(data.offer.status !== EOfferStatus.DRAFT) {
            setIsSentBefore(true);
          }
          if(_embedded && application === "salesforce" && sessionId === sessionStorage.getItem("sessionId") && data.offer.status === "DRAFT" && data.offer.id === _embedded.offer.id) {

            // if(data.deal) {
            //   if(data.deal.updateCustomer) {
            //     if(_embedded.offer.customer.name !==data.offer.customer.name) {
            //       _embedded.offer.customer = data.offer.customer;
            //     }
            //     else {
            //       _embedded.offer.customer.contactPerson = _embedded.offer.customer.contactPerson===null?data.offer.customer.contactPerson:_embedded.offer.customer.contactPerson;
            //       _embedded.offer.customer.email = _embedded.offer.customer.email===null?data.offer.customer.email:_embedded.offer.customer.email;
            //       _embedded.offer.customer.phone = _embedded.offer.customer.phone===null?data.offer.customer.contphoneactPerson:_embedded.offer.customer.phone;
            //     }
            //   }
            //   else {
                  _embedded.offer.customer.contactPerson = _embedded.offer.customer.contactPerson===null?data.offer.customer.contactPerson:_embedded.offer.customer.contactPerson;
                  _embedded.offer.customer.email = _embedded.offer.customer.email===null?data.offer.customer.email:_embedded.offer.customer.email;
                  _embedded.offer.customer.phone = _embedded.offer.customer.phone===null?data.offer.customer.contactPerson:_embedded.offer.customer.phone;

//              }

//            }
            setOffer(_embedded.offer);
            //sessionStorage.setItem("embedded",JSON.stringify({...data,offer:_embedded.offer}));
          }
          else {       
            setOffer(data.offer);
            //sessionStorage.setItem("embedded",JSON.stringify(data));
          }
        }

        sessionStorage.setItem("sessionId",sessionId);
        sessionStorage.setItem("useId",attributes.useid as string || sessionStorage.getItem("useId") || "");

        const [templ] = await Promise.all([
            onFindUserTemplates()
          ]);
        
        setUserTemplates(templ);
      }
    })();
  
  },[]);

  const openProposal = (oid:string) => {
    const type = "edit";
    window.location.href = "/embedded/" + type + "/" + id + "/" + dealid + "/" + userEmail
      + "?integration=salesforce"
      + "&opportunityId=" + attributes.opportunityId
      + "&organizationId=" + attributes.organizationId
      + "&userId=" + attributes.userId
      + "&sessionId=" + attributes.sessionId
      + "&email=" + attributes.email
      + "&useid=" + oid;

  }

  const view = ((): string => {
    const params = qs.parse(location.search);
    return params.view as string;
  })();

  const isUpdate = id !== "add";

  const [pageView, setPageView] = useState(type);
  const [offer, setOffer] = useState<IOffer>(defaultOffer);
  //const [offer, setOffer] = useSessionStorage("embedded",defaultOffer);

  useEffect(()=>{
    sessionStorage.setItem("embedded",JSON.stringify({...embedded, offer:offer}));
  },[offer])


  const [initDone,setInitDone]=useState<boolean>(false);

  const setOfferAfterInit = (o:IOffer) => {
    if(initDone) {
      setOffer(o);
      sessionStorage.setItem("embedded",JSON.stringify({...embedded,offer:o}));
    }
  }

  const currentUser:IUser = (offer && offer.author) || {
    id:"",
    email:userEmail,
    name:(offer && offer.author && offer.author.name) || "",
    role:EUserRole.SALES_PERSON,
    roleString:"",
    phoneNumber:"",
    allowLogin:false,
    organizationId:"",
    firstName:"",
    lastName:"",
    imageURL:"",
    stripeCustomerId:"",
    stripePlanId:"",
    stripeSubscriptionId:"",
    createdUnixTime:Date.now(),
    isRestricted:false,
    isDeleted:false,
  };


  const [offerBlockItems, setOfferBlockItems] = useState<IOfferBlockItems>({});
  const [blockItemsLoading, setBlockItemsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
  const [blockItemModal, setBlockItemModal] = useState(defaultBlockItemModal);
  const [customerHasChanged, setCustomerHasChanged] = useState(false);
  //const [saved, setSaved] = useState(false);
  const [hideEditableContent, setHideEditableContent] = useState(false);
  const [blockLoading, setBlockLoading] = useState("");

  const [isSent, setIsSent] = useState(false);

  const [isSentBefore, setIsSentBefore] = useState(false);

  /**
   * Convert blocks array to object with id as param name
   * @param userTemplate
   */
  const getBlocksObject = (
    userTemplate: IUserTemplate,
    blockType: EUserTemplateBlockType
  ): { [id: string]: IUserTemplateAreaBlock } => {
    const { header, main, footer } = userTemplate;
    //const { main } = userTemplate;
    //return main.blocks //_.concat([], header.blocks, main.blocks, footer.blocks)
    return _.concat([], header.blocks, main.blocks, footer.blocks)
      .filter(block => block && block.blockType === blockType)
      .reduce((current: any, item) => {
        current[item.id] = item;
        return current;
      }, {});
  };

  const fetchBlockItemsWithContent = async (blockItemIds: Array<string>) => {
    let newOfferBlockItems = { ...offerBlockItems };
    const blockItems = await Promise.all(
      blockItemIds.map(async blockItemId => {
        let blockItem = newOfferBlockItems[blockItemId];
        if (!blockItem) {
          blockItem = await onGetBlockItem(blockItemId);
        }
        newOfferBlockItems = {
          ...newOfferBlockItems,
          [blockItemId]: blockItem
        };
        return blockItem;
      })
    );
    setOfferBlockItems(newOfferBlockItems);
    return blockItems;
  };

  /**
   * Vähän tyhmä, mutta tällä saadaan helpoiten piilotettua kun select avautuu niin ylimääräinen harmaa taustasta.
   */
  useEffect(() => {
    if (type === EPageView.EDIT) {
      document.body.style.backgroundColor = "#FFFFFF";
    } else {
      document.body.style.backgroundColor = "#ECECEC";
    }
    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [type]);

  const initializeTemplate = ():IUserTemplate => {
    //console.log("initialize");
    const userTemplate = userTemplates.find(
      item => offer && offer.userTemplate && item.id === offer.userTemplate.id
    );
    if (userTemplate) {
      const cloneUserTemplate = _.cloneDeep(userTemplate);
      const dynamicBlocks = getBlocksObject(
        cloneUserTemplate,
        EUserTemplateBlockType.DYNAMIC
      );
      const dynamicOfferBlocks = getBlocksObject(
        offer.userTemplate,
        EUserTemplateBlockType.DYNAMIC
      );

      let blockItemIds: Array<string> = [];
      for (let key in dynamicOfferBlocks) {
        const offerBlock = dynamicOfferBlocks[key];
        if (offerBlock.offerBlockItems) {
          blockItemIds = _.union(
            blockItemIds,
            offerBlock.offerBlockItems
              .filter(offerBlockItem => !offerBlockItem.editOnOffer)
              .map((offerBlockItem: IBlockItem) => offerBlockItem.id)
          );
        }
      }
      // Fetches blocks items

      (async() => {

      const blockItems = await fetchBlockItemsWithContent(blockItemIds);

      for (let key in dynamicBlocks) {
        const offerBlock = dynamicOfferBlocks[key];
        if (offerBlock && offerBlock.offerBlockItems) {
          dynamicBlocks[
            key
          ].offerBlockItems = offerBlock.offerBlockItems.map(
            (blockItem: IBlockItem) => {
              return blockItem.editOnOffer
                ? blockItem
                : blockItems.find(
                    (item: IBlockItem) => item && item.id === blockItem.id
                  ); // sets fetched data to block
            }
          );
        }
      }

      })();
      // Update simple blocks, maybe done wrong way beause if block is deleted wrong userTemplate item disappeares
      const simpleBlocks = getBlocksObject(
        cloneUserTemplate,
        EUserTemplateBlockType.SIMPLE
      );
      const simpleOfferBlocks = getBlocksObject(
        offer.userTemplate,
        EUserTemplateBlockType.SIMPLE
      );

      for (let key in simpleOfferBlocks) {
        const offerBlock = simpleOfferBlocks[key];
        const block = simpleBlocks[key];

        if (offerBlock && offerBlock.content && block && block.content) {
          for (let content of offerBlock.content) {
            if (content.editOnOffer || content.contentType==="PRODUCTS" || content.contentType==="PRIVATE2") {
              const indexOf = block.content.findIndex(
                item => content.id === item.id
              );
              if (indexOf !== -1) {
                block.content[indexOf] = content;
              }
            }
          }
        }
      }

      return {...cloneUserTemplate,settings:{...offer.userTemplate.settings,temporary:offer.userTemplate.settings.temporary}};

    }

    return null;


  }

  useEffect(() => {
    if (isUpdate) {
      (async () => {
        setInitLoading(true);
        const offer = await onGetOffer(id);
        if (!offer) {
          setInitLoading(false);
          return;
        }

        if (!offer.expirationDate) {
          offer.expirationDate = dateToString(addDays(new Date(), 30));
        }

        setEdit(offer.status === EOfferStatus.DRAFT);

        /**
         * Merges offerBlocksItems to userTemplate maybe only needed if draft.
         * When Edit we need to add more logic to be able to select updated userTemplate if needed.
         */
        if (offer.status === EOfferStatus.DRAFT && offer.userTemplate) {
          const userTemplate = userTemplates.find(
            item => item.id === offer.userTemplate.id
          );
          if (userTemplate) {
            const cloneUserTemplate = _.cloneDeep(userTemplate);
            const dynamicBlocks = getBlocksObject(
              cloneUserTemplate,
              EUserTemplateBlockType.DYNAMIC
            );
            const dynamicOfferBlocks = getBlocksObject(
              offer.userTemplate,
              EUserTemplateBlockType.DYNAMIC
            );

            /**
             * We need to fetch selected blockItems content
             */
            let blockItemIds: Array<string> = [];
            for (let key in dynamicOfferBlocks) {
              const offerBlock = dynamicOfferBlocks[key];
              if (offerBlock.offerBlockItems) {
                blockItemIds = _.union(
                  blockItemIds,
                  offerBlock.offerBlockItems
                    .filter(offerBlockItem => !offerBlockItem.editOnOffer)
                    .map((offerBlockItem: IBlockItem) => offerBlockItem.id)
                );
              }
            }
            // Fetches blocks items
            const blockItems = await fetchBlockItemsWithContent(blockItemIds);

            for (let key in dynamicBlocks) {
              const offerBlock = dynamicOfferBlocks[key];
              if (offerBlock && offerBlock.offerBlockItems) {
                dynamicBlocks[
                  key
                ].offerBlockItems = offerBlock.offerBlockItems.map(
                  (blockItem: IBlockItem) => {
                    return blockItem.editOnOffer
                      ? blockItem
                      : blockItems.find(
                          (item: IBlockItem) => item && item.id === blockItem.id
                        ); // sets fetched data to block
                  }
                );
              }
            }

            // Update simple blocks, maybe done wrong way beause if block is deleted wrong userTemplate item disappeares
            const simpleBlocks = getBlocksObject(
              cloneUserTemplate,
              EUserTemplateBlockType.SIMPLE
            );
            const simpleOfferBlocks = getBlocksObject(
              offer.userTemplate,
              EUserTemplateBlockType.SIMPLE
            );

            for (let key in simpleOfferBlocks) {
              const offerBlock = simpleOfferBlocks[key];
              const block = simpleBlocks[key];

              if (offerBlock && offerBlock.content && block && block.content) {
                for (let content of offerBlock.content) {
                  if (content.editOnOffer || content.contentType==="PRODUCTS") {
                    const indexOf = block.content.findIndex(
                      item => content.id === item.id
                    );
                    if (indexOf !== -1) {
                      block.content[indexOf] = content;
                    }
                  }
                }
              }
            }

            offer.userTemplate = {...cloneUserTemplate,settings:{...offer.userTemplate.settings,temporary:offer.userTemplate.settings.temporary}};

          }
          if(offer.customer) {
            offer.customer = {
              ...customers.find(customer => ((customer.id && customer.id === offer.customer.id) || (customer.pipedrivePersonId && String(customer.pipedrivePersonId) === String(offer.customer.pipedrivePersonId)) )),
              ...offer.customer,
              language: offer.customer.language
            };
          }
          offer.organization = organization;
        }


        setInitLoading(false);
        setOffer({ ...defaultOffer, ...offer });
        setInitDone(true); 

      })();
    } else {
      (async () => {
        setInitLoading(true);
        setEdit(true);

        setInitDone(true);

        if (offer) {
          if (!offer.expirationDate)
            offer.expirationDate = dateToString(addDays(new Date(), 30));
          if (organization) offer.organization = organization;
          setOffer({ ...defaultOffer, ...offer });
        }

        setInitLoading(false);
      })();
    }
    // eslint-disable-next-line
  }, [id, onGetOffer, setOffer, onFindUserTemplates, isUpdate]);

  useEffect(() => { // BUGME
    if (offer.userTemplate  && (!offersLoading && !initLoading)) {
      onUserTemplateChanged(offer.userTemplate);
    }
    // eslint-disable-next-line
  }, [offer.id, offer.userTemplate]); // && offer.userTemplate.id]);

  useEffect(() => {
    if (organization && offer.organization===null) {
      const newOffer = { ...offer };
      newOffer.organization = organization;
      setOffer(newOffer);
    }
    // eslint-disable-next-line
  }, [organization]);

  const viewClickHandler = (type: EPageView) => {
    /*
    if(type == EPageView.EDIT && offer.status !== EOfferStatus.CONFIRMED) {

      setOffer({...offer,status:EOfferStatus.DRAFT, userTemplate: initializeTemplate()});
    }
    */
    setPageView(type);
    //history.push(`/offers/${id}/${type}`);
    history.push(`/embedded/${type}/add/deal/${userEmail}${location.search}`);
    
    if(type === EPageView.PREVIEW) {
      window.scrollTo(0, 0);
    }
  };

  const onDynamicBlockItemChanged = async (
    blockId: string,
    blockItems: Array<IBlockItem>
  ) => {
    return new Promise<void>(async resolve => {
      const fetchBlockItems: Array<IBlockItem> = [];
      const editOnOfferBlockItems: Array<IBlockItem> = [];
      blockItems.forEach(blockItem => {
        if (blockItem.editOnOffer) {
          editOnOfferBlockItems.push(blockItem);
        } else {
          fetchBlockItems.push(blockItem);
        }
      });

      const blockItemsWithContent = await fetchBlockItemsWithContent(
        fetchBlockItems.map(blockItem => blockItem.id)
      );
      const newOffer = { ...offer };
      const blocks = getBlocksObject(
        newOffer.userTemplate,
        EUserTemplateBlockType.DYNAMIC
      );
      const block = blocks[blockId];

      if (block) {
        let newOfferBlockItems: Array<IBlockItem> = [];
        // this forces the order to be same
        blockItems.forEach(blockItem => {
          let addOfferBlockItem;
          if (blockItemsWithContent) {
            addOfferBlockItem = blockItemsWithContent.find(
              blockItemWithContent => blockItemWithContent.id === blockItem.id
            );
          }

          if (!addOfferBlockItem) {
            addOfferBlockItem = editOnOfferBlockItems.find(
              editOnOfferBlockItem => editOnOfferBlockItem.id === blockItem.id
            );
          }

          if (addOfferBlockItem) {
            newOfferBlockItems.push({ ...addOfferBlockItem });
          }
        });

        block.offerBlockItems = newOfferBlockItems;
      }

      setOffer(newOffer);
      resolve();
    });
  };

  const onSimpleContentChanged = (
    block: IUserTemplateAreaBlock,
    blockContent: IBlockContent
  ) => {
    const newOffer = { ...offer };
    const blocks = getBlocksObject(
      newOffer.userTemplate,
      EUserTemplateBlockType.SIMPLE
    );
    try {
      const newBlock = blocks[block.id];
      const indexOf = newBlock.content.findIndex(
        item => item.id === blockContent.id
      );
      if (indexOf !== -1) {
        newBlock.content[indexOf] = { ...blockContent, editOnOffer: true };
      }
    } catch(err) {
      //console.log("ERROR",err);
    }

    setOffer(newOffer);
  };

  const getBlocks = (userTemplate: IUserTemplate) => {
    if (!userTemplate) return null;
    const blocks = [];
    const { header, main, footer } = userTemplate;
    //const { main } = userTemplate;
    if (header.blocks) blocks.push(...header.blocks);
    if (main.blocks) blocks.push(...main.blocks);
    if (footer.blocks) blocks.push(...footer.blocks);
    return blocks;
  };

  const onUserTemplateChanged = async (userTemplate: IUserTemplate) => {
    if (userTemplate) {
      const newOffer = update(offer, {
        userTemplate: { $set: userTemplate }
      });

      const blocks = getBlocks(newOffer.userTemplate);
      
      if (blocks) {
        const blockIds = Array.from(
          new Set(
            blocks
              .filter(
                block => block.blockType === EUserTemplateBlockType.DYNAMIC
              )
              .map(block => block.blockId)
          )
        );
        setBlockItemsLoading(true);
        if (blockIds) {
          const blockItems = await Promise.all(
            blockIds.map(async blockId => {
              return { blockId, blockItems: await onFindBlockItems(blockId) };
            })
          );

          blocks.forEach(block => {
            const item = blockItems.find(
              item => item.blockId === block.blockId
            );
            if (item) {
              block.blockItems = item.blockItems;
            }
            if (block.offerBlockItems) {
              block.blockItems = [
                ...(block.blockItems || []),
                ...block.offerBlockItems.filter(
                  blockItem => blockItem && blockItem.editOnOffer
                )
              ];
            }
          });
        }
        setBlockItemsLoading(false);
      }
    }
  };

  const saveOfferCustomer = async (zoffer: IOffer, params?: string, isSelfSent:boolean=false) => {
    //console.log("saveOfferCustomer");
      saveOrUpdateOffer(zoffer,params,isSelfSent);
  };

  const saveAsDraftHandler = () => {
    const newOffer = { ...offer };
    newOffer.status = EOfferStatus.DRAFT;
    saveOfferCustomer(newOffer);
  };

  const sendOfferHandler = () => {
    const newOffer = { ...offer };
    newOffer.status = EOfferStatus.SENT;
    setSendDialogOpen(false);
    saveOfferCustomer(newOffer);
    setIsSent(true);
  };


  const selfSendOfferHandler = () => {
    //console.log("selfSendOfferHandler");
    const newOffer = { ...offer,status:EOfferStatus.SELFSENT };

    saveOfferCustomer(newOffer,"selfsent=email",true);
    setIsSent(true);
  };

  const saveOrUpdateOffer = async (newOffer: IOffer, params?: string,isSelfSent:boolean=false) => {
    //console.log("saveOrUpdateOffer");
    //let offer:IOffer=null;    

    const savedOffer = await onPostEmbedded(userEmail,"sendproposal",newOffer, application);
    
    // close hubspot window
    //window.parent.postMessage(JSON.stringify({"action": "DONE"}), "*");

    setOffer(savedOffer); //newOffer);
    sessionStorage.setItem("useId",savedOffer.id);

    if(isSelfSent) {
      //console.log("BUG1", window.embeddedAuth);
      //console.log("BUG2", location.search);
      //window.location.href=`/embedded/preview/add/deal/${window.embeddedAuth}?${location.search}`;
      //history.push(`/embedded/sendemail/add/deal/${location.search}`); //${window.embeddedAuth}`);
      history.push(`/embedded/sendemail/add/deal/${window.embeddedAuth}${location.search}`);
    }
    else {
      // close hubspot window
      window.parent.postMessage(JSON.stringify({"action": "DONE"}), "*");
    }

    //console.log("BUGME",window.embeddedAuth);

  };

  const onAddBlockItem = async (id: string, blockId: string, editingStyle: EBlockEditingStyle) => {

    switch(editingStyle) {
      case EBlockEditingStyle.LIST:
        setBlockItemModal({
          open: true,
          id,
          blockId,
          blockItem: null
        });
        break;
      case EBlockEditingStyle.INLINE:
        const newOffer = { ...offer };
        const blocks = getBlocksObject(
          newOffer.userTemplate,
          EUserTemplateBlockType.DYNAMIC
        );

        setBlockLoading(blockId);
        const dbBlock = await onGetBlock(blockId);

        const block = blocks[id];
        const newBlockItem = { editOnOffer: true, id: uuid(), name: '', content: dbBlock.content };
        const newOfferBlockItems = [...(block.offerBlockItems || []), newBlockItem];
        block.offerBlockItems = newOfferBlockItems;
        block.blockItems = [...(block.blockItems || []), newBlockItem];
        
        setOffer(newOffer);
        setBlockLoading("");
        break;
      default:
    }
  };

  const onEditBlockItem = (id: string, blockItem: IBlockItem) => {
    setBlockItemModal({
      open: true,
      id,
      blockId: null,
      blockItem
    });
  };

  const blockItemChangedHandler = async (id: string, blockItem: IBlockItem) => {
    const newOffer = { ...offer };
    const blocks = getBlocksObject(
      newOffer.userTemplate,
      EUserTemplateBlockType.DYNAMIC
    );

    const block = blocks[id];
    if (blockItem.editOnOffer) {
      block.offerBlockItems =
        block.offerBlockItems &&
        block.offerBlockItems.map(item =>
          item.id === blockItem.id ? blockItem : item
        );
      block.blockItems =
        block.blockItems &&
        block.blockItems.map(item =>
          item.id === blockItem.id ? blockItem : item
        );
    } else {
      const newBlockItem = { ...blockItem, editOnOffer: true, id: uuid() };

      let newOfferBlockItems = [];

      if (block.offerBlockItems) {
        newOfferBlockItems = [...block.offerBlockItems];

        const index = block.offerBlockItems.findIndex(
          item => item.id === blockItem.id
        );

        if (index !== -1) {
          newOfferBlockItems[index] = newBlockItem;
        } else {
          newOfferBlockItems.push(newBlockItem);
        }
      } else {
        newOfferBlockItems.push(newBlockItem);
      }

      let newBlockItems = [];

      if (block.blockItems) {
        newBlockItems = [...block.blockItems, newBlockItem];
      } else {
        newBlockItems.push(newBlockItem);
      }

      block.offerBlockItems = newOfferBlockItems;
      block.blockItems = newBlockItems;
    }

    setOffer(newOffer);
    setBlockItemModal(defaultBlockItemModal);
  };

  const saveDraftText = isUpdate ? "Update draft" : "Save as draft";
  
  /*
  let comments = null;
  if (offer) {
    comments = offer.comments;
  }
  */

  const offerDone = isOfferDone(offer);

  const isValid = (): boolean => {
    if (!offer) return false;
    const { customer, userTemplate } = offer;
    return customer &&
      customer.email &&
      isValidEmail(customer.email) &&
      userTemplate &&
      userTemplate.id &&
      offer.expirationDate &&
      stringToDate(offer.expirationDate) > new Date()
      ? true
      : false;
  };

  // REMOVE UNWANTED DATA FROM PROPOSAL
  const removeUnwanted = (blocks:IUserTemplateAreaBlock[]) => {
    if(blocks) {
      blocks.forEach((block:IUserTemplateAreaBlock) => {
        if(block.content) {
          block.content.forEach((content:any)=>{
            if(content.contentType==="PRODUCTS") {
              content.products=content.products.filter((ob:IProduct)=>!ob.library)
            }
          })
        }
      })
    }
  }
  
  const editHandler = (pageView: EPageView) => {
    setConfirmDialog({
      onOk: () => {
        viewClickHandler(pageView);
        setEdit(true);
        setConfirmDialog(defaultConfirmDialog);

        setOffer({...offer, status:EOfferStatus.DRAFT, userTemplate:initializeTemplate()});
      },
      isOpen: true,
      title: "Are you sure?",
      content: "Editing the proposal is permanent."
    });
  };

  const openSendDialog = () => {
    removeUnwanted(offer.userTemplate.header.blocks)
    removeUnwanted(offer.userTemplate.main.blocks)
    removeUnwanted(offer.userTemplate.footer.blocks)

    if (offer.recipients && offer.recipients.length > 0) {
      let recipients = Array<string>();
      offer.recipients.forEach(recipient => {
        if (recipient && recipient.trim() !== "" && isValidEmail(recipient))
          recipients.push(recipient);
      });
      const newOffer = { ...offer, recipients };
      
      setOffer(newOffer);
    }
    setSendDialogOpen(true);
  };

  const selfSendOk = () => {
    setSendDialogOpen(false);
    //history.push(`/offers/${EPageView.FUNNEL}`);
    if(application === "hubspot") {
      // close hubspot window
      window.parent.postMessage(JSON.stringify({"action": "DONE"}), "*");
    }
    else {      
      history.push(`/embedded/preview/add/deal/${window.embeddedAuth}${location.search}`);
    }
  }

  const [message, setMessage] = useState("");

  const resetForm = () => {
    setMessage("");
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const comment: IOfferComment = {
      id: offer.id,
      created: null,
      user: null,
      senderName: "",
      message
    };
    
    //onCommentOffer(comment);

    const savedComment = await onPostEmbedded(userEmail,"commentoffer",comment, application);

    setOffer(savedComment);
    resetForm();
  };

  const isInValid = () => {
    if (!message.length) return true;
    return false;
  };

  return offersLoading || initLoading || embedded === null ? (
    <Spinner color="primary" />
  ) : offersError ? (
    <Alert color="danger">{offersError}</Alert>
  ) : (
    <React.Fragment>

      <OfferBlockItemModal
        blockItemModal={blockItemModal}
        closeSidebar={() => setBlockItemModal(defaultBlockItemModal)}
        onSave={blockItemChangedHandler}
      />

      <CustomDialog
        loading={false}
        onOk={confirmDialog.onOk}
        okButtonText={"OK"}
        onCancel={() => setConfirmDialog(defaultConfirmDialog)}
        open={confirmDialog.isOpen}
        title={confirmDialog.title}
        isValid={true}
      >
        {confirmDialog.content}
      </CustomDialog>

      <OfferSendDialog
        isOpen={sendDialogOpen || type===EPageView.SENDEMAIL}
        viewType={type}
        setIsOpen={setSendDialogOpen}
        onSend={sendOfferHandler}
        onSelfSend={selfSendOfferHandler}
        offer={offer}
        isValid={isValid()}
        history={history}
        onCancel={()=>setIsSent(false)}
        onSelfSendOk={selfSendOk}
      />

      <OfferContext.Provider
        value={{
          offer,
          setOffer:setOfferAfterInit,
          onDynamicBlockItemChanged,
          onSimpleContentChanged,
          blockItemsLoading,
          userTemplates,
          userTemplatesLoading,
          customers,
          customersLoading,
          onAddBlockItem,
          onEditBlockItem,
          customerHasChanged,
          setCustomerHasChanged,
          hideEditableContent,
          setHideEditableContent,
          onBlockItemChanged: blockItemChangedHandler,
          blockLoading,

          onSearchCustomers,
          onGetCustomer,

          currentUser,
        }}
      >
        {application === "salesforce" && offerList && offerList.length > 0 && <>
            <div className={classes.blockHeader}><div className={classes.headerIcon}><FontAwesomeIcon icon={faFileContract} /></div>Proposals</div>

            <div className={classes.blockBody}>
              {offerList.map((ol:any) => (
                <Button className={classes.linkbtn} color="link" key={ol.key} disabled={ol.key === offer.id} onClick={()=>openProposal(ol.key)}>
                  {ol.name!=="null"?ol.name:"-"}<br/>
                  Created: {ol.created}<br/>
                  Status: {ol.status}<br/>
                </Button>
              ))}

              <Button className={classes.linkbtn} color="link" onClick={()=>openProposal("new")}><br/>Create new proposal<br/><br/></Button>
            </div>
            <br/>
        </>}

        {offer.status === EOfferStatus.DRAFT ? <>
          <OfferEdit isVisible={type === EPageView.EDIT} integrationData={embedded} embedded={true}/>
          <OfferPreview offer={offer} isVisible={type === EPageView.PREVIEW} currentUser={currentUser} />
        </>
        :<>
          {application === "salesforce" && <>
          
            {offer.email && <>
              <div className={classes.blockHeader}><div className={classes.headerIcon}><FontAwesomeIcon icon={faEnvelope} /></div>Email</div>
              <div className={classes.blockBody}>
                <pre><b>{offer.email.title}</b><br/><br/>{offer.email.body}</pre>
              </div>
            </>}

            <br/>

            <div className={classes.blockHeader}><div className={classes.headerIcon}><FontAwesomeIcon icon={faHistory} /></div>History</div>
            <div className={classes.blockBody}>
              {offer.history && offer.history.map((item:IOfferHistory)=>{
                return (<div key={item.id}>
                  {item.offerStatus === "CONFIRMED" && <div style={{"color":"#27AE60"}}>
                    Signed on {item.created}
                  </div>}

                  {item.offerStatus === "SENT" && <div>
                    Sent on {item.created}
                    {item.views?item.views.map((v, index)=>{
                      return (<div key={"s_"+index} style={{"paddingLeft":10}}>
                        {v.viewDate} viewed {v.viewCount} times
                      </div>)
                    }):<div style={{"paddingLeft":10}}>No views</div>}
                  </div>}
                </div>)
              })}
            </div>

            <br/>

            <div className={classes.blockHeader}>
              <div className={classes.headerIcon}>
                <FontAwesomeIcon icon={faComments} />
              </div>
              Comments
            </div>

            <div className={classes.blockBody}>
   
              {offer.comments && offer.comments.map((item, index)=>{
                return (<div key={"c_"+index}>
                  <b>{item.created} {item.senderName}{item.user && <>{item.user.firstName} {item.user.lastName}</>}</b>
                  <br/>
                  {item.message}
                </div>)
              })}

              <Form onSubmit={e => submitHandler(e)}>
                <FormGroup>
                  <TextareaAutosize
                    className={["form-control", inputClasses.Input].join(" ")}
                    placeholder="Add comment"
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                    minRows={2}
                  />
                </FormGroup>
                <Button color="success" type="submit" disabled={isInValid()}>
                  Send
                </Button>
              </Form>

            </div>
          
            <br/>
          </>}
          
          <OfferPreview offer={offer} isVisible={true} currentUser={currentUser} />
          
        </>}
        
        {type === EPageView.PREVIEW &&
          <OfferMessagingSidebarSimple loading={emailLoading} />}

      </OfferContext.Provider>
        {!currentUser.isRestricted && (
          <OfferFooter
            offer={offer}
            pageView={pageView}
            onViewChanged={viewClickHandler}
            onSaveDraft={saveAsDraftHandler}
            onSave={openSendDialog}
            saveDraftText={saveDraftText}
            status={offer.status}
            isEdit={isEdit}
            isOfferSent={isSent}
            onEdit={editHandler}
            isUpdate={isUpdate}
            offerDone={offerDone}
            isSentBefore={isSentBefore}
          />
        )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    organization: state.organizations.organization,
    customersLoading: state.customers.loading,
    customers: state.customers.customers,
    customer: state.customers.customer,
    userTemplates: state.userTemplates.userTemplates,
    userTemplatesLoading: state.userTemplates.loading,
    offersLoading: state.offers.loading,
    offersError: state.offers.error,
    commentError: state.offers.commentError,
    commentLoading: state.offers.commentLoading,
    emailLoading: state.offers.emailLoading
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetEmbedded: (id, func, params="") => dispatch(actions.getEmbedded(id, func, params)),
    onPostEmbedded: (id, func, data, application) => dispatch(actions.postEmbedded(id, func, data, application)),

    onFindUserTemplates: () => dispatch(actions.findUserTemplates()),
    onFindBlockItems: id => dispatch(actions.findBlockItems(id, null)),
    onGetBlockItem: id => dispatch(actions.getBlockItem(id)),
    onSaveBlockItem: (blockId, blockItem) => dispatch(actions.saveBlockItem(blockId, blockItem)),
    onSaveOffer: offer => dispatch(actions.saveOffer(offer)),
    onUpdateOffer: (id, offer, params) => dispatch(actions.updateOffer(id, offer, params)),
    onGetOffer: id => dispatch(actions.getOffer(id, null, null)),
    onGetBlock: (id) => dispatch(actions.getBlock(id)),
    onGetUserOrganization: () => dispatch(actions.getUserOrganization()),

    onSearchCustomers: queryString =>
      dispatch(actions.searchCustomers(queryString)),
    onGetCustomer: id => dispatch(actions.getCustomer(id)),

    //onCommentOffer: data => dispatch(actions.commentOffer(data)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmbeddedOffer);
